/* stylelint-disable */

.c-progress {
  display: block;
  margin-top: 12px;
}

@keyframes dots {
  0%,
  20% {
    animation-timing-function: ease-out;
    left: 0;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  35% {
    animation-timing-function: linear;
    left: 45%;
  }

  65% {
    animation-timing-function: ease-in;
    left: 55%;
  }

  75% {
    opacity: 1;
  }

  80%,
  100% {
    left: 100%;
    opacity: 0;
  }
}

.c-progress.f-indeterminate-local {
  position: relative;
}

.c-progress.f-indeterminate-local span {
  animation: orbit 5.5s infinite;
  position: absolute;
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.c-progress.f-indeterminate-local span:after {
  background: #0067b8;
  border-radius: 100%;
  content: '';
  position: absolute;
}

.c-progress.f-indeterminate-local span:nth-child(2) {
  animation-delay: 240ms;
}

.c-progress.f-indeterminate-local span:nth-child(3) {
  animation-delay: 480ms;
}

.c-progress.f-indeterminate-local span:nth-child(4) {
  animation-delay: 720ms;
}

.c-progress.f-indeterminate-local span:nth-child(5) {
  animation-delay: 960ms;
}

.c-progress.f-indeterminate-local.f-progress-large,
.c-progress.f-indeterminate-local.f-progress-large span {
  height: 60px;
  width: 60px;
}

.c-progress.f-indeterminate-local.f-progress-large span:after {
  height: 6px;
  width: 6px;
}

.c-progress.f-indeterminate-local.f-progress-small {
  height: 20px;
  width: 20px;
}

.c-progress.f-indeterminate-local.f-progress-small span {
  height: 25px;
  width: 25px;
}

.c-progress.f-indeterminate-local.f-progress-small span:after {
  height: 3px;
  width: 3px;
}

.c-progress.f-indeterminate-local.f-progress-xsmall,
.c-progress.f-indeterminate-local.f-progress-xsmall span {
  height: 12px;
  width: 12px;
}

.c-progress.f-indeterminate-local.f-progress-xsmall span:after {
  height: 1px;
  width: 1px;
}

@keyframes orbit {
  0% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: rotate(225deg);
  }

  7% {
    animation-timing-function: linear;
    transform: rotate(345deg);
  }

  30% {
    animation-timing-function: ease-in-out;
    transform: rotate(455deg);
  }

  39% {
    animation-timing-function: linear;
    transform: rotate(690deg);
  }

  70% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: rotate(815deg);
  }

  75% {
    animation-timing-function: ease-out;
    transform: rotate(945deg);
  }

  76% {
    opacity: 0;
    transform: rotate(945deg);
  }

  100% {
    opacity: 0;
    transform: rotate(945deg);
  }
}

.page-loader {
  margin-bottom: 50px;
  margin-left: 48.5%;
  margin-top: 50px;
  min-height: 100px;
  text-align: center;

  loader {
    left: calc(50% - 60px);
    position: relative;
  }
}

.small-loader {
  text-align: center;

  loader {
    left: calc(50% - 25px);
    position: relative;
  }
}
